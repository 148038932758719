import { FC, useMemo } from 'react';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { Option } from '../../../Option';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const PickListPreview: FC<ActionPreviewBaseProps<Option<string, string>[]>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const { riskLevel } = useFormAction();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const answer = useMemo(() => {
    if (!response) {
      return ['-'];
    }

    return response?.map((x) => x.text);
  }, [response]);

  if (answerOnly) {
    return <>{answer.join(', ')}</>;
  }

  return <ActionPreview data-cy="picklist-preview" riskLevel={riskLevel} question={title} description={previewDescription} answer={answer} />;
};

export default PickListPreview;
